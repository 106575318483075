import {
  BannerProps,
  ButtonSize,
  Color,
  ColorPreset,
  Banner,
  useTheme,
  BannerButtonVariant,
  BannerVariant,
  BannerLayout,
  BannerLeftAccessoryType,
  Glyph,
} from "@gocardless/flux-react";
import { useRouter } from "next/router";
import { forwardRef, ForwardRefRenderFunction, useCallback } from "react";
import { ClassicCard } from "@braze/web-sdk";

import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import {
  ContentName,
  DismissibleContent,
} from "src/components/ui/DismissibleContent";
import { Route, RouteURLParams, routerPush } from "src/common/routing";
import { RouteParams, rawPaths } from "src/common/routing/routes";
import { useBraze } from "src/technical-integrations/braze/useBraze";

export interface BrazeBannerProps {
  card: ClassicCard;
}

const BrazeBannerComponent: ForwardRefRenderFunction<
  HTMLDivElement,
  BrazeBannerProps
> = ({ card }, ref) => {
  const { id, title, description, extras } = card;
  const router = useRouter();

  const { theme } = useTheme();
  const { sendEvent } = useSegment();

  const { getBrazeInstance } = useBraze();
  const braze = getBrazeInstance();

  const triggerBannerViewedEvent = useCallback(() => {
    sendEvent(TrackingEvent.BANNER_VIEWED, {
      page: router.pathname,
      name: id,
    });

    if (card) {
      braze?.logContentCardImpressions([card]);
    }

    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!braze) return null;

  const parsedRouteParams = (
    paramsString: string | undefined,
    requiredKeys: string[] | undefined
  ): RouteParams => {
    if (
      (!paramsString && requiredKeys?.length === 0) ||
      typeof paramsString !== "string"
    ) {
      return {};
    }

    const params = paramsString
      .split(";")
      .map((pair) => pair.split(":"))
      .reduce((acc, [key, value]) => {
        if (!key || !value) {
          throw new Error("Invalid format");
        }
        acc[key as string] = value;
        return acc;
      }, {} as RouteParams);

    if (requiredKeys && requiredKeys.length > 0) {
      const missingKeys = requiredKeys.filter((key) => !(key in params));
      if (missingKeys.length > 0) {
        throw new Error(`Missing required keys: ${missingKeys.join(", ")}`);
      }
    }

    return params;
  };

  const cardDismiss = () => {
    const cachedCardsStr = localStorage.getItem("cachedCards");
    if (cachedCardsStr) {
      const cachedCards = JSON.parse(cachedCardsStr);

      // Filter out the dismissed card
      const updatedCards = cachedCards.filter(
        (cachedCard: ClassicCard) => cachedCard.id !== card.id
      );

      // Save the updated array back to localStorage
      localStorage.setItem("cachedCards", JSON.stringify(updatedCards));
    }
  };

  const getRouteParamsInfo = (route: Route | undefined): string[] => {
    if (!route) {
      throw new Error(`Invalid route: ${route}`);
    }

    const routePattern = rawPaths[route];
    if (!routePattern) {
      throw new Error(`Invalid route: ${route}`);
    }

    // Use a regular expression to find all dynamic segments (e.g., ':param')
    const paramNames = (routePattern.match(/:([^/]+)/g) || []).map((param) =>
      param.slice(1)
    );

    return paramNames;
  };

  const backgroundColor =
    extras?.backgroundColor && extras.backgroundColor in Color
      ? theme.color(Color[extras.backgroundColor as keyof typeof Color])
      : theme.color(ColorPreset["BackgroundLight_02"]);
  const layout =
    extras?.layout && extras.layout in BannerLayout
      ? BannerLayout[extras.layout as keyof typeof BannerLayout]
      : BannerLayout.Vertical;
  const leftAccessoryType =
    extras?.leftAccessoryType &&
    extras.leftAccessoryType in BannerLeftAccessoryType
      ? BannerLeftAccessoryType[
          extras.leftAccessoryType as keyof typeof BannerLeftAccessoryType
        ]
      : null;
  const leftAccessoryGlyph =
    extras?.leftAccessoryGlyph && extras.leftAccessoryGlyph in Glyph
      ? Glyph[extras.leftAccessoryGlyph as keyof typeof Glyph]
      : null;

  const showLeftAccessory =
    (leftAccessoryType === BannerLeftAccessoryType.Icon &&
      leftAccessoryGlyph) ||
    (leftAccessoryType === BannerLeftAccessoryType.Tag &&
      extras?.leftAccessoryName);

  const secondaryRoute =
    extras?.secondaryRoute && extras.secondaryRoute in Route
      ? Route[extras.secondaryRoute as keyof typeof Route]
      : undefined;

  const primaryRoute =
    extras?.primaryRoute && extras.primaryRoute in Route
      ? Route[extras.primaryRoute as keyof typeof Route]
      : undefined;

  let showSecondaryButton = false,
    showPrimaryButton = false;
  let primaryRouteParams = {},
    primaryQueryParams = {};
  let secondaryRouteParams = {},
    secondaryQueryParams = {};
  try {
    showPrimaryButton = Boolean(
      extras?.primaryButtonText && (extras?.primaryRouteHref || primaryRoute)
    );
    if (showPrimaryButton && !extras?.primaryRouteHref) {
      primaryRouteParams = parsedRouteParams(
        extras?.primaryRouteRouteParams,
        getRouteParamsInfo(primaryRoute)
      );
      primaryQueryParams = parsedRouteParams(
        extras?.primaryRouteQueryParams,
        []
      );
    }
  } catch {
    // If route params cannot be parsed, banner can't serve it purpose, do not render it.
    return null;
  }
  try {
    showSecondaryButton = Boolean(
      extras?.secondaryButtonText &&
        (extras?.secondaryRouteHref || secondaryRoute)
    );
    if (showSecondaryButton && !extras?.secondaryRouteHref) {
      secondaryRouteParams = parsedRouteParams(
        extras?.secondaryRouteRouteParams,
        getRouteParamsInfo(secondaryRoute)
      );
      secondaryQueryParams = parsedRouteParams(
        extras?.secondaryRouteQueryParams,
        []
      );
    }
  } catch {
    return null;
  }

  return (
    <DismissibleContent
      ref={ref}
      name={id ? (id as ContentName) : (title as ContentName)}
      onContentMount={triggerBannerViewedEvent}
      renderContent={(dismiss) => (
        <Banner
          variant={BannerVariant.Light}
          css={{
            backgroundColor: backgroundColor,
            boxShadow: "none",
            filter: "none",
          }}
          layout={layout}
          title={title}
          leftAccessory={
            showLeftAccessory
              ? ({
                  type: leftAccessoryType,
                  name: leftAccessoryGlyph,
                  text: extras?.leftAccessoryName,
                } as BannerProps["leftAccessory"])
              : undefined
          }
          secondaryAction={
            showSecondaryButton
              ? {
                  control: "button",
                  children: extras.secondaryButtonText,
                  buttonVariant:
                    layout === BannerLayout.Vertical
                      ? BannerButtonVariant.Plain
                      : BannerButtonVariant.Secondary,
                  size: ButtonSize.Sm,
                  onClick: () => {
                    if (extras?.secondaryButtonDismiss) {
                      if (card) {
                        braze.logCardDismissal(card);
                      }
                      cardDismiss();
                      dismiss();
                      return;
                    }
                    if (card) {
                      braze.logContentCardClick(card);
                    }
                    extras?.secondaryRouteHref
                      ? window.open(extras?.secondaryRouteHref)
                      : routerPush({
                          route: secondaryRoute,
                          routeParams: secondaryRouteParams,
                          queryParams: secondaryQueryParams,
                        } as RouteURLParams);
                  },
                }
              : undefined
          }
          primaryAction={
            showPrimaryButton
              ? {
                  control: "button",
                  children: extras.primaryButtonText,
                  buttonVariant:
                    layout === BannerLayout.Vertical
                      ? BannerButtonVariant.Primary
                      : BannerButtonVariant.Plain,
                  size: ButtonSize.Sm,
                  onClick: () => {
                    if (card) {
                      braze.logContentCardClick(card);
                    }
                    extras?.primaryRouteHref
                      ? window.open(extras?.primaryRouteHref)
                      : routerPush({
                          route: primaryRoute,
                          routeParams: primaryRouteParams,
                          queryParams: primaryQueryParams,
                        } as RouteURLParams);
                  },
                }
              : undefined
          }
          closeAction={{
            onClose: () => {
              if (card) {
                braze.logCardDismissal(card);
              }
              cardDismiss();
              dismiss();
            },
            label: "Close",
          }}
        >
          {description}
        </Banner>
      )}
    />
  );
};

export const BrazeBanner = forwardRef(BrazeBannerComponent);

BrazeBanner.displayName = "BrazeBanner";
