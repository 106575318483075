import { forwardRef, useCallback } from "react";
import { useRouter } from "next/router";
import { DateFormatter } from "@internationalized/date";
import { Trans } from "@lingui/macro";
import {
  Banner,
  BannerStatus,
  BannerVariant,
  useTheme,
  BannerLeftAccessoryType,
} from "@gocardless/flux-react";
import { useV3MigrationPrimaryAdminUserList } from "@gocardless/api/dashboard/v3-migration-primary-admin-user";
import { useUserShowSelf } from "@gocardless/api/dashboard/user";

import {
  DismissibleContent,
  ContentName,
} from "src/components/ui/DismissibleContent";
import { UserLocale, useI18n } from "src/technical-integrations/i18n";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";

const bannerName = "Pricing Update";

export const PricingUpdateBanner = forwardRef<HTMLDivElement, {}>(
  (_props, ref) => {
    const { data: user } = useUserShowSelf();
    const { data: migrationUsers } = useV3MigrationPrimaryAdminUserList({
      user_id: user?.users?.id,
    });

    const [locale] = useI18n();
    const dateFormatter = (userLocale: UserLocale) =>
      new DateFormatter(userLocale, {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });

    const { theme } = useTheme();

    const { sendEvent } = useSegment();
    const router = useRouter();
    const triggerBannerViewedEvent = useCallback(() => {
      sendEvent(TrackingEvent.BANNER_VIEWED, {
        page: router.pathname,
        name: bannerName,
      });
      // TODO: Fix exhaustive dependencies
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.pathname]);

    if (!migrationUsers?.v3_migration_primary_admin_users?.[0]) {
      return null;
    }

    const adminUser = migrationUsers.v3_migration_primary_admin_users[0];
    const {
      email_subject: emailSubject,
      primary_admin_email: primaryAdminEmail,
      date_email_sent: dateEmailSent,
    } = adminUser;

    const pricingUpdateDateEmailSent = new Date(
      dateEmailSent as unknown as string
    );

    const formattedDateEmailSent = dateFormatter(locale).format(
      pricingUpdateDateEmailSent
    );

    return (
      <DismissibleContent
        ref={ref}
        name={ContentName.PricingUpdateBannerDismissed}
        onContentMount={triggerBannerViewedEvent}
        renderContent={(dismiss) => (
          <Banner
            title={
              <Trans id="pricing-update-banner.heading">
                Your pricing will change on 1st June 2024
              </Trans>
            }
            variant={BannerVariant.Light}
            leftAccessory={{
              type: BannerLeftAccessoryType.Status,
              status: BannerStatus.Warning,
            }}
            closeAction={{
              label: <Trans id="Close">Close</Trans>,
              onClose: () => {
                sendEvent(TrackingEvent.BANNER_DISMISSED, {
                  page: router.pathname,
                  name: bannerName,
                });
                dismiss();
              },
            }}
            css={{
              marginBottom: theme.spacing(1),
            }}
          >
            <Trans id="pricing-update-banner.description-text">
              Please see the email <strong>{emailSubject}</strong> sent to{" "}
              <strong>{primaryAdminEmail}</strong> on {formattedDateEmailSent}{" "}
              for more details, including how to request a different plan{" "}
              <strong>before 3rd May 2024</strong>
            </Trans>
          </Banner>
        )}
      />
    );
  }
);
