import {
  Interpose,
  Section,
  Space,
  Separator,
  Box,
} from "@gocardless/flux-react";

import { ReportingContextProvider } from "../../ui/Reporting/components/ReportingContextProvider";
import { Reporting } from "../../ui/Reporting";
import { OutboundPaymentBalance } from "../../HomeOutboundPaymentBalance";

import { useMerchantMaturity } from "./hooks/useMerchantMaturity";
import { Header } from "./sections/Header";
import { ActionItems } from "./sections/ActionItems";
import { CreditorBalance } from "./sections/CreditorBalance";
import { QuickActions } from "./sections/QuickActions";
import { NeedHelp } from "./sections/NeedHelp";
import { SuggestedActions } from "./sections/SuggestedActions";
import { AccountSetupStepper } from "./sections/AccountSetup";

import { HomeOutboundAccountHeader } from "src/components/HomeOutboundAccountHeader";
import { HomeCollectionOverviewHeader } from "src/components/HomeCollectionOverviewHeader";
import { useOrganisation } from "src/libraries/organisation/hooks";
import { Route } from "src/common/routing";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSendPageViewEvent } from "src/technical-integrations/segment/useSendPageViewEvent";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import PrimaryPageLayout from "src/components/layout/PrimaryPage/PrimaryPageLayout";
import { useReportingExperiment } from "src/components/ui/Reporting/components/experiments/hooks/useReportingExperiment";

interface Section {
  id: string;
  component: React.FC;
}

const HeaderSection: Section = {
  id: "header",
  component: Header,
};

const SuggestedActionsItemSection: Section = {
  id: "suggested_actions",
  component: SuggestedActions,
};

const ActionItemSection: Section = {
  id: "action_items",
  component: ActionItems,
};

const OutboundPaymentBalanceSection: Section = {
  id: "outbound_payment_balance",
  component: () => (
    <>
      <Box spaceBelow={2}>
        <HomeOutboundAccountHeader />
      </Box>
      <OutboundPaymentBalance />
    </>
  ),
};

const SeparatorSection: Section = {
  id: "separator",
  component: () => (
    <Section>
      <Separator />
    </Section>
  ),
};

const getCreditorBalanceSection = (
  outboundPaymentsEnabled: boolean
): Section => ({
  id: "creditor_balance",
  component: () => (
    <>
      {outboundPaymentsEnabled && (
        <Box spaceBelow={2}>
          <HomeCollectionOverviewHeader />
        </Box>
      )}
      <CreditorBalance />,
    </>
  ),
});

const ReportingSection: Section = {
  id: "reporting",
  component: () => (
    <ReportingContextProvider>
      <Reporting />
    </ReportingContextProvider>
  ),
};

const LastActivitySection: Section = {
  id: "last_activity",
  component: QuickActions,
};

const NeedHelpSection: Section = {
  id: "need_help",
  component: NeedHelp,
};

const AccountSetupSection: Section = {
  id: "account_setup",
  component: AccountSetupStepper,
};

const getBalanceSections = (context: ContextProps) => {
  if (context.outboundPaymentsEnabled) {
    return [
      OutboundPaymentBalanceSection,
      SeparatorSection,
      getCreditorBalanceSection(context.outboundPaymentsEnabled),
    ];
  }

  return [getCreditorBalanceSection(context.outboundPaymentsEnabled)];
};

const getPartnerMerchantOnboardingSections = (context: ContextProps) => [
  HeaderSection,
  AccountSetupSection,
  ...getBalanceSections(context),
  NeedHelpSection,
];

const getNonActivatedMerchantSections = (context: ContextProps) => {
  if (context.shouldRenderPartnerMerchantOnboardingImprovements) {
    return getPartnerMerchantOnboardingSections(context);
  }
  return [
    HeaderSection,
    ...getBalanceSections(context),
    LastActivitySection,
    NeedHelpSection,
  ];
};

const getReportingSections = (context: ContextProps) => {
  if (context.isReportingEnabled) {
    return [ReportingSection];
  }
  return getBalanceSections(context);
};

interface ContextProps {
  isActivatedMerchant: boolean;
  isReportingEnabled: boolean;
  isSuggestedActionsEnabled: boolean;
  outboundPaymentsEnabled: boolean;
  shouldRenderPartnerMerchantOnboardingImprovements: boolean;
}
const getActivatedMerchantSections = (context: ContextProps) => [
  ActionItemSection,
  HeaderSection,
  ...(context.isSuggestedActionsEnabled ? [SuggestedActionsItemSection] : []),
  ...getReportingSections(context),
  LastActivitySection,
  NeedHelpSection,
];

const getSections = (context: ContextProps): Section[] =>
  context.isActivatedMerchant
    ? getActivatedMerchantSections(context)
    : getNonActivatedMerchantSections(context);

export const Home: React.FC = () => {
  const { activeExperimentName: activeReportingExperimentName } =
    useReportingExperiment();

  useSendPageViewEvent(
    TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_PAGE_VIEWED,
    ...(activeReportingExperimentName
      ? [
          {
            reporting_experiment_name: activeReportingExperimentName,
          },
        ]
      : [])
  );
  const { isActivatedMerchant } = useMerchantMaturity();

  const organisation = useOrganisation();
  const outboundPaymentsEnabled =
    organisation?.outbound_payments_enabled ?? false;

  const { isVariationOn: isReportingEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.MERCHANT_ENGAGEMENT_REPORTING_V1,
  });

  const { isVariationOn: isSuggestedActionsEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.ASAP_PAYMENTS_SUGGESTED_ACTIONS,
  });

  const { isVariationOn: isPartnerMerchantOnboardingImprovementsEnabled } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.VERIFICATIONS_PARTNER_MERCHANT_ONBOARDING_IMPROVEMENTS,
    });

  const shouldRenderPartnerMerchantOnboardingImprovements =
    !!organisation?.is_partner_merchant &&
    isPartnerMerchantOnboardingImprovementsEnabled;

  const sections = getSections({
    isActivatedMerchant,
    isReportingEnabled,
    isSuggestedActionsEnabled,
    outboundPaymentsEnabled,
    shouldRenderPartnerMerchantOnboardingImprovements,
  });

  return (
    <PrimaryPageLayout highlightedMenuLink={Route.Home}>
      <Interpose node={<Space v={[3, null, 3]} />}>
        {sections.map(({ component: Component, id }) => (
          <div key={id} data-testid={id}>
            <Component />
          </div>
        ))}
      </Interpose>
    </PrimaryPageLayout>
  );
};
