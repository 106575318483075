import { forwardRef, useCallback } from "react";
import { useRouter } from "next/router";
import { Trans } from "@lingui/macro";
import {
  Banner,
  BannerVariant,
  BannerLeftAccessoryType,
  BannerLayout,
  Color,
  BannerButtonVariant,
  ButtonSize,
} from "@gocardless/flux-react";

import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import {
  DismissibleContent,
  ContentName,
} from "src/components/ui/DismissibleContent";
import { ToTranslate } from "src/components/i18n";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import {
  OptimizelyFlag,
  OptimizelyVariant,
} from "src/technical-integrations/optimizely/constants";
import { Route, routerPush } from "src/common/routing";
import { useUserPermissions } from "src/common/hooks/user-permissions/useUserPermissions";
import { useOrganisationWithType } from "src/libraries/organisation/hooks";

const bannerName = "Flexible Payouts - Opt In";
const flexiblePayoutsInfoUrl =
  "https://support.gocardless.com/hc/en-gb/articles/18625103245084-Manage-weekly-monthly-or-daily-payout-schedules";

export const FlexiblePayoutsBannerOptIn = forwardRef<HTMLDivElement, {}>(
  (_props, ref) => {
    const { isVariationOn: isFlexiblePayoutsEnabled } = useOptimizelyVariation({
      flag: OptimizelyFlag.ASAP_PAYMENTS_ENABLE_FLEXIBLE_PAYOUTS,
      variationKey: OptimizelyVariant.OPT_IN,
    });

    const { sendEvent } = useSegment();
    const router = useRouter();
    const triggerBannerViewedEvent = useCallback(() => {
      sendEvent(TrackingEvent.BANNER_VIEWED, {
        page: router.pathname,
        name: bannerName,
      });
      // TODO: Fix exhaustive dependencies
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.pathname]);

    const { isPaymentProvider } = useOrganisationWithType();

    const userPermissions = useUserPermissions();

    if (
      !isFlexiblePayoutsEnabled ||
      !userPermissions.isAdmin ||
      isPaymentProvider
    ) {
      return null;
    }

    return (
      <DismissibleContent
        ref={ref}
        name={ContentName.FlexiblePayoutsOptInBannerDismissed}
        onContentMount={triggerBannerViewedEvent}
        renderContent={(dismiss) => (
          <Banner
            elevation={0}
            variant={BannerVariant.Light}
            layout={BannerLayout.Horizontal}
            backgroundColor={Color.Ocean_100}
            leftAccessory={{
              type: BannerLeftAccessoryType.Tag,
              text: "New",
            }}
            primaryAction={{
              control: "button",
              children: <ToTranslate>Change preferences</ToTranslate>,
              buttonVariant: BannerButtonVariant.Primary,
              size: ButtonSize.Sm,
              onClick: () => {
                sendEvent(TrackingEvent.BANNER_PRIMARY_CTA_CLICKED, {
                  page: router.pathname,
                  name: bannerName,
                  destination: Route.Payouts,
                });
                routerPush({
                  route: Route.Payouts,
                  queryParams: { action: "preferences-settings" },
                });
              },
            }}
            secondaryAction={{
              control: "link",
              target: "_blank",
              children: <Trans id="Learn more">Learn more</Trans>,
              buttonVariant: BannerButtonVariant.Plain,
              size: ButtonSize.Sm,
              onClick: () => {
                sendEvent(TrackingEvent.BANNER_SECONDARY_CTA_CLICKED, {
                  page: router.pathname,
                  name: bannerName,
                  destination: flexiblePayoutsInfoUrl,
                });
              },
              href: flexiblePayoutsInfoUrl,
            }}
            closeAction={{
              label: <Trans id="Close">Close</Trans>,
              onClose: () => {
                sendEvent(TrackingEvent.BANNER_DISMISSED, {
                  page: router.pathname,
                  name: bannerName,
                });
                dismiss();
              },
            }}
          >
            <ToTranslate>
              Get paid out when it suits your business the most
            </ToTranslate>
          </Banner>
        )}
      />
    );
  }
);
