import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { useV3MigrationPrimaryAdminUserList } from "@gocardless/api/dashboard/v3-migration-primary-admin-user";
import {
  AlignItems,
  Box,
  ButtonGutter,
  ButtonSize,
  ButtonVariant,
  Color,
  ColorPreset,
  Glyph,
  HoverEffect,
  IconButton,
  JustifyContent,
  P,
  PlainLink,
  Space,
  Tag,
  TagColor,
  TagVariant,
  Text,
  TypePreset,
  Visibility,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useRouter } from "next/router";
import { forwardRef, useCallback } from "react";

import {
  useSupportedBankAccountDataCreditor,
  useDefaultCreditor,
} from "src/libraries/creditor/hooks";
import { TrackingEvent } from "src/common/trackingEvents";
import {
  ContentName,
  DismissibleContent,
} from "src/components/ui/DismissibleContent";
import { LinkBuilder, Route } from "src/components/routing";
import { useSegment } from "src/technical-integrations/segment/useSegment";

const bannerName = "Bank Account Data";

export const BadBanner = forwardRef<HTMLDivElement, {}>((_props, ref) => {
  const { sendEvent } = useSegment();
  const router = useRouter();
  const creditor = useDefaultCreditor();
  const { data: user } = useUserShowSelf();
  const { data: migrationUsers } = useV3MigrationPrimaryAdminUserList({
    user_id: user?.users?.id,
  });

  const { isCreditorSupported } = useSupportedBankAccountDataCreditor(creditor);

  const triggerBannerViewedEvent = useCallback(() => {
    sendEvent(TrackingEvent.BANNER_VIEWED, {
      page: router.pathname,
      name: bannerName,
    });
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  if (
    Object.keys(migrationUsers?.v3_migration_primary_admin_users?.[0] || {})
      .length
  ) {
    return null;
  }

  if (!isCreditorSupported()) {
    return null;
  }
  return (
    <DismissibleContent
      ref={ref}
      name={ContentName.BadBannerDismissed}
      onContentMount={triggerBannerViewedEvent}
      renderContent={(dismiss) => {
        const closeButton = (
          <IconButton
            icon={Glyph.Close}
            label={<Trans id="Close">Close</Trans>}
            variant={ButtonVariant.TextOnLight}
            onClick={() => {
              sendEvent(TrackingEvent.BANNER_DISMISSED, {
                page: router.pathname,
                name: bannerName,
              });
              dismiss();
            }}
            size={{ base: ButtonSize.Sm, gutters: ButtonGutter.Sm }}
          />
        );

        return (
          <Box
            gutterH={1}
            gutterV={1}
            borderRadius={1}
            layout="flex"
            alignItems={[AlignItems.Start, null, AlignItems.Center]}
            justifyContent={JustifyContent.SpaceBetween}
            bg={Color.Evergreen_100}
          >
            <Box
              layout="flex"
              flexDirection={["column", null, "row"]}
              justifyContent={JustifyContent.SpaceBetween}
              flexGrow={1}
              width="100%"
              spaceAfter={[0, null, 1]}
            >
              <Box
                layout="flex"
                flexDirection={["column", null, "row"]}
                alignItems={[AlignItems.Start, null, AlignItems.Center]}
              >
                <Box
                  layout="flex"
                  justifyContent={JustifyContent.SpaceBetween}
                  flexGrow={1}
                  width={["100%", null, "initial"]}
                >
                  <Tag variant={TagVariant.Solid} color={TagColor.Primary}>
                    <Text textTransform="uppercase">
                      <Trans id="bank-account-data-banner.new-tag">New</Trans>
                    </Text>
                  </Tag>
                  <Visibility visible={["block", null, "none"]}>
                    {closeButton}
                  </Visibility>
                </Box>

                <Space layout="inline" h={1} />
                <Visibility visible={["block", null, "none"]}>
                  <Space v={1} />
                </Visibility>
                <P
                  color={ColorPreset.TextOnLight_01}
                  preset={TypePreset.Body_02}
                >
                  <Trans id="bank-account-data-banner.description-text">
                    Make more informed decisions with customer data with
                  </Trans>{" "}
                  <LinkBuilder
                    route={Route.BankAccountData}
                    onClick={() => {
                      sendEvent(
                        TrackingEvent.BANK_ACCOUNT_DATA_GO_TO_VALUE_PROP_CLICKED,
                        {
                          page: router.pathname,
                          position: "banner",
                        }
                      );
                      sendEvent(TrackingEvent.BANNER_PRIMARY_CTA_CLICKED, {
                        page: router.pathname,
                        name: bannerName,
                        destination: Route.BankAccountData,
                      });
                    }}
                  >
                    {(result) => (
                      <PlainLink
                        {...result}
                        effect={HoverEffect.TextDecoration}
                        textDecoration="underline"
                      >
                        <Trans id="bank-account-data-banner.link-text">
                          Bank Account Data
                        </Trans>
                      </PlainLink>
                    )}
                  </LinkBuilder>
                </P>
              </Box>
            </Box>
            <Visibility visible={["none", null, "block"]}>
              {closeButton}
            </Visibility>
          </Box>
        );
      }}
    />
  );
});
